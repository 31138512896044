<template>
  <NuxtLink :href="href" class="rounded-2xl border-none bg-transparent hover:bg-transparent sm:bg-gradient-to-b from-white to-zinc-50 transform transition will-change-transform hover:-translate-y-1 sm:hover:drop-shadow-2xl sm:ring-1 ring-stone-100 sm:shadow-lg sm:shadow-stone-200 hover:shadow-none duration-700">
    <article class="h-full">
      <div class="flex flex-col h-full items-stretch">

        <div class="z-0 relative">
          <div class="z-10 relative flex flex-1 flex-col max-sm:rounded-lg sm:rounded-t-2xl overflow-hidden">
            <div :class="[imgZoomClassname]" :style="`filter:contrast(${imgContrast});`">
              <NuxtImg
                       lazy
                       :src="src"
                       :alt="alt || caption"
                       :modifiers="{ 'vib': vibrance, 'max-h': '400', 'max-w': '500' }"
                       fit="cover" quality="60"
                       densities="1"
                       width="600" height="250"
                       sizes="(max-width: 400px) 80vw, (max-width: 640px) 80vw, 100vw"
                       class="z-20 h-auto w-full max-xl:min-h-64 max-h-48 sm:max-h-64 lg:max-h-48 xl:max-h-40 object-cover [transition:transform_6s_ease-out] hover:[transform:scale(1.28)] hover:[transition:transform_0.6s_ease-in]" />
            </div>
          </div>
        </div>

        <div class="mt-4 px-1 relative flex flex-1 flex-col">
          <div class="flex flex-1 flex-col gap-y-2">
            <div class="pr-3 flex flex-1 justify-end items-center">
              <div v-if="priceInfo" class="-translate-y-8 py-0.5 px-3 tracking-wider rounded-2xl max-w-fit bg-white"><span class="text-xs">p.P. ab</span> <span class="text-accent-500 text-xl sm:text-xl">{{ priceInfo }} &euro;</span></div>
              <div v-else class="h-8" />
            </div>
          </div>
        </div>

        <div class="-mt-6 px-2 sm:px-6 pb-6 mx-auto flex flex-col flex-wrap items-stretch justify-between h-full w-full">
          <div class="flex items-center">
            <NuxtIcon name="material-symbols-light:luggage-outline-rounded" size="24px" class="-ml-1 mr-1 text-mongoose-500 min-w-6" />
            <div>
              <CHeading v-if="hotelName" :heading="heading" class="uppercase text-xl tracking-wide">{{ hotelName }}</CHeading>
            </div>
          </div>
          <div v-if="caption" class="pb-2 text-sm text-neutral-500">{{ caption }}</div>
          <div v-if="hotelName" class="font-bold text-xl text-mongoose-500 tracking-wide">{{ "*".repeat(stars) }}</div>
          <div class="text-sm">{{ description }}
            <div>
              <slot />
            </div>
          </div>
          <p v-if="subline" class="mt-2 text-sm text-mongoose-400 uppercase tracking-wider">{{ subline }}</p>
          <CButton v-if="hotelName" class="mt-4 mx-2 bg-red-300 text-sm sm:place-self-end sm:text-end rounded overflow-hidden max-w-fit">Details</CButton>
        </div>
      </div>

    </article>
  </NuxtLink>
</template>

<script setup lang="ts">
export interface Props {
  alt?: string
  caption?: string
  description?: string
  subline?: string
  href?: string
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'clamp' | 'clip' | 'crop' | 'facearea' | 'fillMax' | 'max' | 'min' | 'scale'
  fpX?: number
  fpY?: number
  heading?: string
  hideImg?: boolean
  hotelName: string
  imgContrast?: number
  imgDisclaimer?: string
  imgUrlParams?: string
  priceInfo?: string
  src: string
  stars: number
  vibrance?: number
  zoomDirection?: 'in' | 'out' | 'none'
}

const props = withDefaults(defineProps<Props>(), {
  alt: 'Hotelangebot',
  caption: undefined,
  description: undefined,
  subline: undefined,
  fit: 'contain',
  fpX: undefined,
  fpY: undefined,
  heading: 'h4',
  href: undefined,
  imgContrast: 1.0,
  imgDisclaimer: undefined,
  imgUrlParams: undefined,
  priceInfo: undefined,
  vibrance: 60,
  zoomDirection: 'out',
})

const imgZoomClassname = {
  'in': '[animation:cos-animation-zoom-out_40s_linear_1_0s]',
  'out': '[animation:cos-animation-zoom-in_40s_linear_1_0s]',
  'none': '',
}[props.zoomDirection]

</script>
